class DatabaseDataHandler {
    private readonly endpoint: string;

    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    private endpointData(): { lastId: number; updatedDate: Date; data: any[] } {
        return {
            lastId: 0,
            updatedDate: new Date(),
            data: []
        };
    }

    retrieveUserData(userData: Record<string, any> = {}): Record<string, any> {
        if (!userData[this.endpoint]) {
            return {
                ...userData,
                [this.endpoint]: this.endpointData()
            };
        }

        return userData;
    }

    retrieveEndpointData(userData: Record<string, any>): any[] {
        return userData[this.endpoint]?.data ?? [];
    }
}

export default DatabaseDataHandler;
import { InputAdornment, TextField } from "@mui/material"
import { DynamicInputProps } from "./DynamicInput"
import { fixDecimals, formatRealToDatabase, formatRealToInput } from "utils/utils";

const DynamicCurrencyInput = (props: DynamicInputProps) => {
    const changeCurrency = (value: any) => {
        if (props.onChange) {
            const newValue = formatRealToDatabase(value);
            props.onChange(props.field.id, newValue);
        }
    }

    const onBlur = () => {
        changeCurrency(fixDecimals(props.value))
    }

    return (
        <TextField
            id="standard-adornment-weight"
            type="text"
            placeholder="0.00"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">{ props.field.customSuffix ?? "R$" }</InputAdornment>
                ),
            }}
            autoFocus={props.autoFocus}
            value={formatRealToInput(props.value)}
            onChange={(e) => changeCurrency(e.target.value)}
            disabled={props.disabled}
            onBlur={() => onBlur()}
            fullWidth
            size={props.small ? "small" : "medium"}
            error={props.errors !== undefined && props.errors !== ""}
            onKeyUp={(e) => {
                if (e.key === "Enter") {
                    if (props.enterKey) props.enterKey();
                }
            }}
        />

    )
}

export default DynamicCurrencyInput
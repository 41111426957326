import { faArrowDown, faArrowUp, faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup, Nav, Navbar, Pagination, Table } from "react-bootstrap";
import { SortDirection, TimestampToDate, currencyToNumber, normalizeName, uid, viewData } from "utils/utils";
import { ExcelActionsProps, ImportExcelButton } from "../ImportExcelButton";
import { useNavigate } from "react-router-dom";
import { DocumentData } from "firebase/firestore";
import RequestHandler from "handlers/RequestHandler";
import { Field, FieldTypes, IModelSetup } from "infrastructure/ModelStructure";
import DynamicCreate from "./DynamicCreate";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ExportExcelButton } from "../ExportExcelButton";
import TablePlaceHolder from "../TablePlaceholder";

export type DynamicTableProps = {
    title: string;
    article: string;
    createLink?: string;
    fields: Field[];
    loadingData?: boolean;
    setPage?: (page: number) => void;
    onRowClick?: (data: DocumentData) => void;
    currentPage?: string | null;
    searchQuery?: string | null;
    excelFileName?: string;
    excelActions?: ExcelActionsProps;
    createOnList?: IModelSetup;
    orderBy?: string;
    orderDirection?: "asc" | "desc" | undefined;
    service: RequestHandler;
    model: IModelSetup;
};

type Results = {
    data: DocumentData[];
    page: number;
    totalPages: number;
    fields: Field[];
}

const sortFields = (
    data_a: { [key: string]: any; },
    data_b: { [key: string]: any; },
    sortField: string,
    fields: Field[],
    sort: SortDirection
) => {
    if (!sortField) return 0;
    const field = fields.find(f => f.id === sortField) || fields[0];
    const a = data_a[field.id];
    const b = data_b[field.id];
    const type = field.type;

    switch (type) {
        case FieldTypes.Currency:
            return sort === 'asc' ? currencyToNumber(a) - currencyToNumber(b) : currencyToNumber(b) - currencyToNumber(a);
        case FieldTypes.Number:
            return sort === 'asc' ? parseInt(a) - parseInt(b) : parseInt(b) - parseInt(a);
        case FieldTypes.Date:
            return sort === 'asc' ? TimestampToDate(a).getTime() - TimestampToDate(b).getTime() : TimestampToDate(b).getTime() - TimestampToDate(a).getTime();
        case FieldTypes.Checkbox:
            return sort === 'asc' ? a - b : b - a;
        default:
            return sort === 'asc' ? (a ?? "").localeCompare((b ?? "")) : (b ?? "").localeCompare((a ?? ""));
    }
}


const elaborateResults = (props: {
    fields: Field[],
    data: DocumentData[],
    search: string,
    sortField: string,
    sort: SortDirection,
    page: number,
    setPage: (n: number) => void, singlePaged?: boolean
}
) => {
    const fields = props.fields;
    const searchTerms = props.search.split(":");
    const searchColumn = searchTerms.length > 1 ? searchTerms[0] : "";
    const searchString = searchColumn ? searchTerms[1] : props.search;
    const strict = searchString[0] === '"' && searchString[searchString.length - 1] === '"';
    const s = normalizeName(searchString);
    
    const filteredData = props.data?.filter(d => {
        return props.fields.some(f => {
            const fieldData = viewData(d, f);
            const value = normalizeName(fieldData);
            if(!s) return true;
            if(searchColumn) {
                if(normalizeName(f.label) !== normalizeName(searchColumn)) {
                    return false;
                } 
            }
            if (strict) {
                return value === s;
            }
            
            return value?.includes(s);
        });
    })

    const sortedData = filteredData.sort(
        (a, b) =>
            b.id - a.id
    ).sort(
        (a, b) =>
            sortFields(a, b, props.sortField, fields, props.sort)
    ) || [];


    const totalPages = Math.ceil((sortedData.length || 1) / 10);

    if (props.page > totalPages) {
        props.setPage(totalPages);
    }
    if (props.page < 1) {
        props.setPage(1);
    }

    if (props.singlePaged) {
        return {
            data: sortedData,
            page: 1,
            totalPages: 1,
            fields: fields
        }
    }

    return {
        data: filteredData?.slice((props.page - 1) * 10, props.page * 10) || [],
        page: props.page,
        totalPages: totalPages,
        fields: fields
    }
}


const DynamicTable = (props: DynamicTableProps) => {
    const requester = props.title + " - DynamicTable";
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [page, setPage] = useState(parseInt((props.currentPage || "1")));
    const [sort, setSort] = useState<SortDirection>('desc');
    const [sortField, setSortField] = useState(props.orderBy || "");
    const [search, setSearch] = useState(props.searchQuery || "");
    const navigate = useNavigate();

    const refreshData = () => {
        props.service.refreshData(requester).then(setData)
            .catch(setError)
    }

    const loadData = () => {
        props.service.all(requester).then(setData)
            .catch(setError)
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        loadData();
        // eslint-disable-next-line
    }, [props.title, props.fields]);


    const handleSort = (column: string) => {
        if (sortField === column) {
            setSort(sort === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(column);
            setSort('asc');
        }
    }

    const results: Results = elaborateResults({ fields: props.fields, data: data, search: search, sortField: sortField, sort: sort, page: page, setPage: setPage, singlePaged: props.model.isSetup });

    if (loading) {
        return <TablePlaceHolder rows={10} columns={props.fields.length} />;
    }

    if (error) {
        return <ErrorPage error={error} />;
    }

    return (<Card>
        <Card.Header>
            <Navbar>
                <Card.Title>{props.title}</Card.Title>
                <Nav className="me-auto my-lg-0"></Nav>
            </Navbar>
            {!props.model.isSetup ?
                <div className="list-actions">
                    <Button onClick={() => navigate(props.createLink ?? "/")} variant="success" size="sm"><span><FontAwesomeIcon icon={faPlus} /> Nov{props.article}</span></Button>
                    {props.excelActions && <ImportExcelButton {...props.excelActions} refreshData={refreshData} setLoading={setLoading} size="sm" />}
                    {props.excelActions && <ExportExcelButton {...props.excelActions} size="sm" />}
                    <Button onClick={refreshData} className="mleft-1" variant="text" size="sm"><span><FontAwesomeIcon icon={faRefresh} /></span></Button>
                </div>
                : null}
        </Card.Header>
        <Card.Body className="p-0">
            <ListGroup variant="flush">
                <ListGroup.Item className="p-0">
                    <input type="search" style={{ borderColor: "transparent" }} className="form-control" placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} />
                </ListGroup.Item>
                <TableWrapper results={results} handleSort={handleSort} sortField={sortField} sort={sort} onRowClick={props.onRowClick} createOnList={props.createOnList} getData={refreshData} keyHelper={props.title + "_list_"} />
                {!props.model.isSetup ? <PaginationWrapper page={results.page} totalPages={results.totalPages} setPage={setPage} /> : null}
            </ListGroup>
        </Card.Body>
    </Card>
    );

}

const TableWrapper = (props: { results: Results, handleSort: (id: any) => void, sortField: any, sort: 'asc' | 'desc', onRowClick?: (d: DocumentData) => void, createOnList?: IModelSetup, getData: () => void, keyHelper: string }) => {
    const onRowClick = (data: DocumentData) => {
        if (props.onRowClick) {
            props.onRowClick(data);
        }
    }

    return (
        <Table responsive>
            <thead>
                <tr>
                    {props.results.fields.map((field, index) => {
                        const last = index === props.results.fields.length - 1;
                        const colspan = last && props.createOnList ? 2 : 1;
                        const iconProp: IconProp | null = props.sortField === field.id && props.sort === 'asc' ? faArrowUp : props.sortField === field.id && props.sort === 'desc' ? faArrowDown : null;
                        const icon = iconProp ? <FontAwesomeIcon icon={iconProp} /> : null;
                        const sizeClass = field.fieldSize ? 'size' + field.fieldSize : '';
                        return (
                            <th key={index} className={sizeClass} colSpan={colspan} onClick={() => props.handleSort(field.id)} style={{ textAlign: field.position ?? 'left' }}>
                                {field.label}
                                {icon}
                            </th>
                        )
                    })}
                </tr>
            </thead>
            {props.createOnList ? (<DynamicCreate {...props.createOnList} refresh={props.getData} onTable key={uid()} />) : null}
            <tbody>
                {props.results.data.length === 0 ? (
                    <tr>
                        <td colSpan={props.results.fields.length + (props.createOnList ? 1 : 0)}>
                            Nenhum registro encontrado
                        </td>
                    </tr>
                ) : null}
                {props.results.data.map((res, index1) => (
                    <tr key={props.keyHelper + index1} onClick={() => onRowClick(res)} >
                        {props.results.fields?.map((field, index2) => {
                            const last = index2 === props.results.fields.length - 1;
                            const colspan = last && props.createOnList ? 2 : 1;
                            const textAlign = field.position ?? 'left';
                            return (<td key={props.keyHelper + index2} colSpan={colspan} style={{ textAlign: textAlign }}>{viewData(res, field)}</td>)
                        })}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

const ErrorPage = (props: { error: string }) => {
    return (
        <ListGroup.Item>
            <div className="alert alert-danger">
                {props.error}
            </div>
        </ListGroup.Item>
    );
}


const PaginationWrapper = (props: { page: number, totalPages: number, setPage: (page: number) => void }) => {
    return (
        <div className="pagination-wrapper" key={uid()}>
            <Pagination>
                {props.page > 1 ? (<Pagination.First onClick={() => props.setPage(1)} />) : null}
                {props.page > 1 ? (<Pagination.Prev onClick={() => props.setPage(props.page - 1)} />) : null}
                {props.page > 1 ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(1)} > 1 </Pagination.Item>) : null}
                {props.page > 6 ? (<Pagination.Ellipsis className="d-none d-md-block" onClick={() => props.setPage(props.page - 5)} />) : null}
                {props.page > 5 ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page - 4)}>{props.page - 4}</Pagination.Item>) : null}
                {props.page > 4 ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page - 3)}>{props.page - 3}</Pagination.Item>) : null}
                {props.page > 3 ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page - 2)}>{props.page - 2}</Pagination.Item>) : null}
                {props.page > 2 ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page - 1)}>{props.page - 1}</Pagination.Item>) : null}
                <Pagination.Item active>{props.page}</Pagination.Item>
                {props.page + 1 < (props.totalPages || 1) ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page + 1)}>{props.page + 1}</Pagination.Item>) : null}
                {props.page + 2 < (props.totalPages || 1) ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page + 2)}>{props.page + 2}</Pagination.Item>) : null}
                {props.page + 3 < (props.totalPages || 1) ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page + 3)}>{props.page + 3}</Pagination.Item>) : null}
                {props.page + 4 < (props.totalPages || 1) ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.page + 4)}>{props.page + 4}</Pagination.Item>) : null}
                {props.page + 5 < (props.totalPages || 1) ? (<Pagination.Ellipsis className="d-none d-md-block" onClick={() => props.setPage(props.page + 5)} />) : null}
                {props.page < (props.totalPages || 1) ? (<Pagination.Item className="d-none d-md-block" onClick={() => props.setPage(props.totalPages || 1)}>{props.totalPages}</Pagination.Item>) : null}
                {props.page < (props.totalPages || 1) ? (<Pagination.Next onClick={() => props.setPage(props.page + 1)} />) : null}
            </Pagination>
            <span>Página {props.page} de {props.totalPages}</span>
        </div >
    );
}



export default DynamicTable;
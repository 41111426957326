import { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useAlert } from "provider/AlertProvider";
import { useAuth } from "provider/AuthProvider";


const Register = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const alert = useAlert()
    const auth = useAuth();


    const emailRef = useRef<HTMLInputElement>(null);
    const confirmRef = useRef<HTMLInputElement>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isValid = event.currentTarget.checkValidity();
        if (!isValid) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        await auth.Register(emailRef.current?.value, password).then().catch((error) => {
            alert.showAlert({
                type: "error",
                message: error?.response?.data?.error ?? "Erro ao fazer criar conta! Verifique se o email já não está cadastrado."
            });
            setIsLoading(false);
        });
    }



    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" required ref={emailRef} disabled={isLoading} />
                <Form.Control.Feedback type="invalid">
                    Informe um email válido.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required minLength={8} disabled={isLoading} />
                <Form.Control.Feedback type="invalid">
                    Informe uma senha com no mínimo 8 caracteres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirm">
                <Form.Label>Confirme sua senha</Form.Label>
                <Form.Control type="password" name="confirmPassword" ref={confirmRef} required pattern={password} disabled={isLoading} />
                <Form.Control.Feedback type="invalid">
                    As senhas não conferem.
                </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" variant="success" className="w-100" disabled={isLoading}>
                {isLoading ? "Carregando..." : "Registrar!"}
            </Button>
            <NavLink to="/" className="btn btn-link w-100">Voltar para tela de login!</NavLink>
        </Form>
    )
}


export default Register;
import { SortDirection, colorList, currencyFormater, dataSearch, dataSort, getMonthYear } from 'utils/utils';
import SortIcon from 'templates/components/SortIcon';
import TablePlaceHolder from 'templates/components/TablePlaceholder';
import React, { useEffect } from 'react';
import { Card, Col, ListGroup, Row, Table } from 'react-bootstrap';
import { MenuItem, Select } from '@mui/material';
import { CategoryRequestHandler } from 'handlers/request-handlers/CategoryRequestHandler';
import { Chart, ArcElement } from 'chart.js'
import { Pie } from 'react-chartjs-2';

Chart.register(ArcElement);

const CategoryAnalysis = () => {
    const [search, setSearch] = React.useState<string>("");
    const [queryData, setQueryData] = React.useState<any>({});
    const [sortBy, setSortBy] = React.useState<string>("name");
    const [sortOrder, setSortOrder] = React.useState<SortDirection>("asc");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [category, setCategory] = React.useState<string>("");
    const [categoryList, setCategoryList] = React.useState<string[]>([]);
    const [monthList, setMonthList] = React.useState<string[]>([]);
    const [selectedMonth, setSelectedMonth] = React.useState<number>(0);

    const headers = [
        { field: "name", label: "Nome" },
        { field: "totalValue", label: "Total Mês" },
        { field: "average", label: "Média Mês" },
    ];

    const expenseRender = (expense: any, index: number) => {
        return (
            <tr key={index}>
                <td>{expense.name}</td>
                <td>{currencyFormater.format(expense.totalValue)}</td>
                <td>{currencyFormater.format(expense.average)}</td>
            </tr>
        )
    }

    useEffect(() => {
        const service = new CategoryRequestHandler();
        service.all().then((response: any) => {
            if (!response || response.length === 0) {
                setLoading(false);
                return;
            }

            const months = response.reduce((acc: any, curr: any) => {
                const m = getMonthYear(curr.month);
                if (!acc.includes(m)) {
                    acc.push(m);
                }
                return acc;
            }, []);

            const catList = Object.keys(response[0].data).sort((a: string, b: string) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            });
            
            setMonthList(months);
            setQueryData(response);
            setCategoryList(catList);
            setCategory(catList[0]);            
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <TablePlaceHolder rows={10} columns={9} />
    }


    if (categoryList.length === 0) {
        return <Card>
            <Card.Header>
                <Card.Title>Não há despesas para mostrar.</Card.Title>
            </Card.Header>
        </Card>
    }

    const handleSort = (field: string) => {
        if (sortBy === field) {
            if (sortOrder === "asc") {
                setSortOrder("desc");
            } else {
                setSortOrder("asc");
            }
        } else {
            setSortBy(field);
        }
    }


    const monthData = queryData[selectedMonth] as any;
    const categoryData = monthData.data[category];
    const totalMonthValue = monthData.totalValue;
    const catData = [] as any[];

    Object.keys(categoryData.data).forEach((key: string, index: number) => {
        catData.push(categoryData.data[key]);
    });



    const listData = dataSort(dataSearch(catData, search), sortBy, sortOrder).map((expense: any, index: number) => expenseRender(expense, index));





    const getPieData = () => {
        const data = [] as any[];
        Object.keys(queryData[selectedMonth].data).forEach((key: string) => {
            const item = queryData[selectedMonth].data[key];
            data.push(item.totalValue);
        });

        const label = categoryList;

        const dataSet = {
            labels: label,
            datasets: [
                {
                    label: 'Total',
                    data: data,
                    backgroundColor: colorList,
                    borderColor: colorList,
                    borderWidth: 1,
                },
            ],
        };

        return dataSet;
    }

    const pieData = getPieData();

    const changeMonth = (month: any) => {
        setSelectedMonth(month);
        const catList = Object.keys(queryData[month].data).sort((a: string, b: string) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });
        setCategoryList(catList);
        const firstCategory = catList[0];
        setCategory(firstCategory);
    }

    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Select value={selectedMonth} onChange={(e) => {
                            changeMonth(e.target.value);
                        }}>
                            {monthList.map((item, index) => <MenuItem key={index} value={monthList.indexOf(item)}>{item}</MenuItem>)}
                        </Select>
                    </Card.Header>
                    <Card.Body>
                        <Pie data={pieData} />
                        <span>Total:
                            <span className='text-success p-1'>{currencyFormater.format(totalMonthValue)}</span>
                        </span>
                    </Card.Body>

                    <Card.Body className="p-0">
                        <ListGroup variant="flush">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Nome da categoria</th>
                                        <th>Total Mês</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categoryList.map((item: string, index: number) => {
                                            const active = category === item ? "active" : "";
                                            return (
                                                <tr key={index} className={active} onClick={() => {
                                                    setCategory(item);
                                                }}>
                                                    <td>{item}</td>
                                                    <td>{currencyFormater.format(queryData[selectedMonth].data[item].totalValue)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        Object.keys(queryData[selectedMonth].data).length === 0 &&
                                        <tr>
                                            <td colSpan={2} className="text-center">Nenhum registro encontrado</td>
                                        </tr>
                                    }
                                    {
                                        Object.keys(queryData[selectedMonth].data).length > 0 &&
                                        <tr>
                                            <td colSpan={2} className="text-center">Clique em uma categoria para ver os detalhes</td>
                                        </tr>
                                    }

                                </tbody>
                            </Table>
                        </ListGroup>
                    </Card.Body>
                    <Card.Header>
                        <b>Despesas de {category}</b>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-0">
                                <input type="search" style={{ borderColor: "transparent" }} className="form-control" placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} />
                            </ListGroup.Item>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        {
                                            headers.map((header, index) => {
                                                return (
                                                    <th key={index} onClick={() => handleSort(header.field)}>{header.label} <SortIcon field={header.field} sortBy={sortBy} SortDirection={sortOrder} /></th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listData.length === 0 &&
                                        <tr>
                                            <td colSpan={8} className="text-center">Nenhum registro encontrado</td>
                                        </tr>

                                    }
                                    {
                                        listData.map((expense) => expense)
                                    }
                                </tbody>
                            </Table>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
        </Row >
    );
};


export default CategoryAnalysis;
import { faArrowDown, faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirection } from "utils/utils";


const SortIcon = (props: {field: string, sortBy: string, SortDirection: SortDirection}) => {
    if (props.sortBy !== props.field) {
        return <FontAwesomeIcon icon={faMinus} />;
    }
    if (props.SortDirection === "asc") {
        return <FontAwesomeIcon icon={faArrowUp} />;
    } else {
        return <FontAwesomeIcon icon={faArrowDown} />;
    }
}

export default SortIcon;
import logo from 'assets/image/gp_400px.png';

type Props = {
    children?: React.ReactNode;
};

const LoginTemplate: React.FC<Props> = ({ children }) => {
    return (
        <main className="centered-box">
            <section className="box bg-white-transparent">
                <div className="login-brand">
                    <img src={logo} alt="GoldPocket Logo" width={200} height={200} />
                </div>
                {children}
            </section>
        </main>
    );
};

export default LoginTemplate;
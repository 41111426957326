import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicForm, DynamicFormProps } from "templates/components/dynamic/DynamicForm";
import { useAlert } from "provider/AlertProvider";
import NotFound from "view/NotFound";
import { IModelSetup, getDefaultService, getFormData } from "infrastructure/ModelStructure";
import Loading from "../Loading";


const DynamicUpdate = (props : IModelSetup) => {
    const requester = props.title + " - DynamicUpdate";
    const id = useParams<{ id: string }>().id ?? "";
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any | null>();
    const navigate = useNavigate();
    const alert = useAlert()
    const service = props.service ?? getDefaultService(props.endpoint);
    

    useEffect(() => {
        service.get(id, requester).then((d) => {
            setData(getFormData(props.fields,d));
            setLoading(false);
        }).catch(() => {
            setData(null);
            setLoading(false);
        })

        // eslint-disable-next-line
    }, []);

    if(props.disableUpdate) {
        return <NotFound />;
    }

    const onSubmit = () => {
        setLoading(true);
        const submitData = {...data};
        if(props.idGen) submitData.id = props.idGen(submitData);
        
        service.update(submitData).then(() => {
            alert.showAlert({
                type: "success",
                message: `${props.title} atualizad${props.article} com sucesso.`
            });
            navigate(props.defaultPath);
        }).catch((e: any) => {
            alert.showAlert({
                type: "error",
                message: `Não foi possível atualizar ${props.article} ${props.title.toLowerCase()}.`
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    if (loading) {
        return <Loading />
    }

    if(!data) {
        return <NotFound />;
    }

    const form : DynamicFormProps = {
        model: props,
        title: `Atualizar nov${props.article} ${props.title.toLowerCase()}`,
        actionLabel: "Atualizar!",
        loading: loading,
        handleSubmit: onSubmit,
        onChangeField: setData,
        data: data,
        size: 6,
        fields: props.fields,
    }

    return <DynamicForm {...form} />
}

export default DynamicUpdate;
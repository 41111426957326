import { IRouteMap } from "handlers/RouteHandler";
import * as fa from "@fortawesome/free-solid-svg-icons";
import Dashboard from "view/private/Dashboard";
import Home from "view/public/Home";
import Login from "view/public/Login";
import Register from "view/public/Register";
import { IModelSetup } from "infrastructure/ModelStructure";
import { ExpenseModel } from "dynamic-models/ExpenseModel";
import { BankAccountModel } from "dynamic-models/BankAccountModel";
import { ContributionModel } from "dynamic-models/ContributionModel";
import { InvestmentsModel } from "dynamic-models/InvestmentsModel";
import { InvoiceModel } from "dynamic-models/InvoiceModel";
import CategoryAnalysis from "view/private/CategoryAnalysis";
import MonthlyExpenses from "view/private/MonthlyExpenses";


/**
 * Repository based routes
 */
export const Repositories : IModelSetup[] = [
    ExpenseModel,
    BankAccountModel,
    ContributionModel,
    InvestmentsModel,
    InvoiceModel,
    // Payments,
]

/**
 * Private routes
 */
export const PrivateRoutes : IRouteMap = {
    Root: {
        path: "/",
        title: "Dashboard",
        element: Dashboard,
        isMenu: true,
        menuIcon: fa.faDashboard
    },
    MonthlyExpenses: {
        path: "/monthly",
        title: "Despesas Mensais",
        element: MonthlyExpenses,
        isMenu: true,
        menuIcon: fa.faCheckCircle
    },
    CategoryAnalysis: {
        path: "/categories",
        title: "Categorias",
        element: CategoryAnalysis,
        isMenu: true,
        menuIcon: fa.faChartBar
    },
    // Monthly: {
    //     path: "/monthly",
    //     title: "Contas Mensais",
    //     element: MonthlyExpenses,
    //     isMenu: true,
    //     menuIcon: fa.faCalendar
    // }
}

/**
 * Public routes
 */
export const PublicRoutes : IRouteMap = {
    Home: {
        path: "/Home",
        title: "Home",
        type: "public",
        element: Home
    },
    Login: {
        path: "/Login",
        title: "Login",
        type: "login",
        element: Login
    },
    Register: {
        path: "/Register",
        title: "Register",
        type: "login",
        element: Register
    },
}
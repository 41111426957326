import { getMonthYear, MonthYearToDate, normalizeName } from "utils/utils";
import RequestHandler from "../RequestHandler";
import { ExpenseModel, ExpenseFields } from "../../dynamic-models/ExpenseModel";
import { Timestamp } from "firebase/firestore";

export type MonthlyExpense = {
    name: string;
    average: number;
    lastPaidValue: number;
    lastPaidDate: Timestamp;
    dueDay: number;
    paymentMethod: string;
    paidCurrentMonth: boolean;
    category: string;
    totalValue: number;
    numberOfRecords: number;
    percentage: number;
    oldExpense: boolean;
    totalLastMonth: number;
    totalCurrentMonth: number;
}

type MonthlyExpenseList = {
    [key: string]: MonthlyExpense;
}

const NUMBER_OF_PAST_MONTHS: number = 3;
const PAST_DATE_LIMIT: number = new Date(new Date().getFullYear(), new Date().getMonth() - NUMBER_OF_PAST_MONTHS, 1).getTime();
const CURRENT_MONTH: string = getMonthYear(new Date());


export class MonthlyRequestHandler extends RequestHandler {
    constructor(endpoint: string = "") {
        super("expense");
    }

    FilterData = (data: any, category: string) : any[] => {
        return data
            .map((expense: any) => {
                const testCategory = expense[ExpenseFields.Category] === category;
                return !testCategory ? null : expense;
            })
            .filter((expense: any) => expense !== null);
    }

    getMonthsFromExpenses = (data: any): string[] => {
        return data.reduce((acc: any, curr: any) => {
            const monthYear = curr.monthref ? curr.monthref : getMonthYear(curr.date.toDate());
            if (!acc.includes(monthYear)) acc.push(monthYear);
            return acc;
        }, []);
    }

    expensesByMonth = (data: any): { [key: string]: MonthlyExpenseList } => {
        const months = this.getMonthsFromExpenses(data);

        const expenseList: { [key: string]: MonthlyExpenseList } = months.reduce((acc: any, curr: string) => {
            acc[curr] = {};
            return acc;
        }, {});

        data.forEach((curr: any) => {
            const id = normalizeName(curr.description);
            const monthId = curr.monthref ? curr.monthref : getMonthYear(curr.date.toDate());
            if(!expenseList[monthId][id]) {
                expenseList[monthId][id] = {} as MonthlyExpense;
            }
            const record = expenseList[monthId][id];
            record.dueDay = curr.date.toDate().getDate();
            record.oldExpense = curr.date.toDate().getTime() < PAST_DATE_LIMIT;
            record.totalValue = record.totalValue ? record.totalValue + Number(curr.value) : Number(curr.value);
            record.numberOfRecords = record.numberOfRecords ? record.numberOfRecords + 1 : 1;
            record.paymentMethod = curr.type;
            record.category = curr.category;
            record.lastPaidDate = curr.date;
            record.lastPaidValue = Number(curr.value);
            record.name = curr.description;
        });

        return expenseList;
    }

    lastMonthExpenses = (data: any) => {
        return data.reduce((acc: any, curr: any) => {
            const description = normalizeName(curr[ExpenseFields.Description]);
            const month = curr[ExpenseFields.MonthReference] ? MonthYearToDate(curr[ExpenseFields.MonthReference]) : curr[ExpenseFields.Date].toDate();
            if (!acc[description]) acc[description] = getMonthYear(month);
            else if (month.getTime() > MonthYearToDate(acc[description]).getTime()) acc[description] = getMonthYear(month);
            return acc;
        }, {});
    }

    getExpenseList = (data: any): string[] => {
        return data.map((curr: any) => {
            return normalizeName(curr.description);
        }).filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);
    }

    calculateAllMonthData = (data: any) => {
        const expenseByMonth = this.expensesByMonth(data);
        const expenseList = this.getExpenseList(data);
        const averageExpenseDescription = {} as any;

        expenseList.forEach((expense: string) => {
            const data = {
                totalValue: 0,
                numberOfRecords: 0,
                average: 0,
                numberOfMonths: 0
            };

            Object.keys(expenseByMonth).forEach((month: string) => {
                if(expenseByMonth[month][expense]) {
                    const totalValue = expenseByMonth[month][expense].totalValue;
                    const numberOfRecords = expenseByMonth[month][expense].numberOfRecords;
                    data.totalValue += totalValue;
                    data.numberOfRecords += numberOfRecords;
                    data.numberOfMonths += 1;
                }
            });

            data.average = data.totalValue / data.numberOfMonths;

            averageExpenseDescription[expense] = data;
        });
        

        return averageExpenseDescription;
    }

    categoryData = (data: any, category: string): MonthlyExpense [] => {
        const d = this.FilterData(data, category);
        const res : MonthlyExpense[] = [];
        const expenseList = this.getExpenseList(d);
        const allMonthData = this.calculateAllMonthData(d);
        const lastMonthdata = this.lastMonthExpenses(d);
        const expenseByMonth = this.expensesByMonth(d);
        var totalExpenseValue = 0;

        expenseList.forEach((expense: string) => {
            const lastMonth = lastMonthdata[expense];
            res.push({
                name: expenseByMonth[lastMonth][expense].name,
                average: allMonthData[expense].average,
                lastPaidValue: expenseByMonth[lastMonth][expense].lastPaidValue,
                lastPaidDate: expenseByMonth[lastMonth][expense].lastPaidDate,
                dueDay: expenseByMonth[lastMonth][expense].dueDay,
                paymentMethod: expenseByMonth[lastMonth][expense].paymentMethod,
                paidCurrentMonth: MonthYearToDate(lastMonth) >= MonthYearToDate(CURRENT_MONTH),
                category: expenseByMonth[lastMonth][expense].category,
                totalValue: allMonthData[expense].totalValue,
                numberOfRecords: allMonthData[expense].numberOfRecords,
                percentage: 0,
                oldExpense: false,
                totalLastMonth: expenseByMonth[lastMonth][expense].totalValue,
                totalCurrentMonth: expenseByMonth[CURRENT_MONTH] ? expenseByMonth[CURRENT_MONTH][expense]? expenseByMonth[CURRENT_MONTH][expense].totalValue : 0 : 0,
            });
            totalExpenseValue += allMonthData[expense].average;
        });
        
        res.forEach((expense) => {
            if(expense.oldExpense) return;
            expense.percentage = (expense.average / totalExpenseValue) * 100;
        });

        return res;
    }

    override all = async (): Promise<any> => {
        const [data] = await this.getUserData();
        const endpointData = data[ExpenseModel.endpoint].data;
        const categoryList = endpointData.map((item: any) => item.category).filter((item: any) => item !== undefined);
        const uniqueCategories = categoryList.filter((item: any, index: number) => categoryList.indexOf(item) === index);
        const allByCategory = uniqueCategories
            .reduce((acc: any, curr: string) => {
            acc[curr] = this.categoryData(endpointData, curr);
            return acc;
        }, {});

        return allByCategory;
    }
}
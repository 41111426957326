import { faArrowDown, faArrowUp, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, FieldTypes } from "infrastructure/ModelStructure";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";


export const colorList = [
    'rgba(185, 96, 219, 1)',
    'rgba(132, 153, 19, 1)',
    'rgba(170, 98, 190, 1)',
    'rgba(140, 94, 249, 1)',
    'rgba(24, 231, 254, 1)',
    'rgba(65, 143, 232, 1)',
    'rgba(166, 198, 11, 1)',
    'rgba(46, 48, 109, 1)',
    'rgba(104, 118, 127, 1)',
    'rgba(237, 86, 136, 1)',
    'rgba(188, 193, 65, 1)',
    'rgba(244, 118, 224, 1)',
    'rgba(89, 77, 152, 1)',
    'rgba(61, 252, 135, 1)',
    'rgba(182, 154, 122, 1)',
    'rgba(189, 238, 217, 1)',
    'rgba(112, 227, 33, 1)',
    'rgba(136, 94, 223, 1)',
    'rgba(161, 182, 240, 1)',
    'rgba(196, 54, 55, 1)',
    'rgba(252, 44, 234, 1)',
    'rgba(193, 58, 174, 1)',
    'rgba(247, 179, 98, 1)',
    'rgba(100, 50, 233, 1)',
    'rgba(105, 218, 68, 1)',
    'rgba(161, 10, 238, 1)',
    'rgba(67, 247, 214, 1)',
    'rgba(4, 50, 192, 1)',
    'rgba(246, 38, 197, 1)',
    'rgba(145, 138, 255, 1)',
    'rgba(13, 6, 57, 1)',
    'rgba(174, 108, 2, 1)',
    'rgba(184, 146, 13, 1)',
    'rgba(242, 84, 197, 1)',
    'rgba(45, 126, 181, 1)',
    'rgba(229, 241, 14, 1)',
    'rgba(118, 193, 225, 1)',
    'rgba(220, 85, 202, 1)',
    'rgba(221, 132, 111, 1)',
    'rgba(200, 249, 7, 1)'
];

export const currentDate = (): string => {
    return dayjs().format('YYYY-MM-DD');
}

export const currencyFormater = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const currencyToNumber = (value: string) => {
    let v = value;
    if (value.toString()[0] === "R") {
        v = value.toString().replace("R$", "").replace(".", "").replace(",", ".");
    }
    return Number(v);
}

export const numberToCurrency = (value: number, suffix?: string) => {
    const number = currencyFormater.format(value);
    if (suffix) {
        return number.replace("R$", suffix);
    }
    return number;
}

export const formatDate = (date: string) => {
    const dateElements = date.split('/');
    const year = Number(dateElements[2]);
    const month = Number(dateElements[1]);
    const day = Number(dateElements[0]);

    const jsDate = new Date(year, month - 1, day);
    return dateFormater.format(jsDate);
}

export const dateFormater = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});


export const currentDateInput = currentDate();

export const currentMonthInput = currentDateInput.split('-')[0] + "-" + currentDateInput.split('-')[1];

export const uid = () => Date.now().toString(36) + Math.random().toString(36);


export const formatedDatetoInputDate = (month?: string) => {
    if (!month) {
        return currentDateInput;
    }
    return month.split('/')[2] + "-" + month.split('/')[1] + "-" + month.split('/')[0];
}

export const formatDateInputToDateString = (date: string | Date) => {
    if (typeof date === 'string') {
        const dateElements = date.split('-');
        const year = Number(dateElements[0]);
        const month = Number(dateElements[1]);
        const day = Number(dateElements[2]);

        const jsDate = new Date(year, month - 1, day);
        return dateFormater.format(jsDate);
    }

    return dateFormater.format(date);

}

export const formatedMonthtoInputMonth = (month?: string) => {
    if (!month) {
        return currentMonthInput;
    }
    return month.split('/')[0] + "-" + month.split('/')[1];
}

export type Any = {
    [key: string]: any;
}

export const inputDateToDatabase = (date: string | Date) => {
    if (typeof date === 'string') {
        const dateElements = date.split('-');
        const year = Number(dateElements[0]);
        const month = Number(dateElements[1]);
        const day = Number(dateElements[2]);

        const jsDate = new Date(year, month - 1, day);
        return jsDate;
    }

    return date;
}

export const getDateFromDatabaseToJsDate = (d: any) => {
    if (d?.seconds) {
        return new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
    }
    return dayjs(d).toDate();
}


export const getDateFromDatabaseToView = (d: any) => {
    if (d?.seconds) {
        const date = new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
        return date.toLocaleDateString('pt-BR');
    }
    return dayjs(d).format('DD/MM/YYYY');
}

export const TimestampToDate = (d: any) => {
    return new Timestamp(d?.seconds ?? 0, d?.nanoseconds ?? 0).toDate();
}

export const DateToTimestamp = (d: any) => {
    return new Timestamp(d.getTime() / 1000, 0);
}

export const mapDataRequest = (data: any, fields: Field[]) => {
    const result: any = {
        id: data.id ?? '',
    };
    fields.forEach(field => {
        if (field.type === FieldTypes.Currency)
            result[field.id] = currencyToNumber(data[field.id] ?? '');
        else if (field.type === FieldTypes.Checkbox)
            result[field.id] = Boolean(data[field.id] ?? false);
        else if (field.type === FieldTypes.Number)
            result[field.id] = Number(data[field.id] ?? 0);
        else if (field.type === FieldTypes.Date)
            result[field.id] = inputDateToDatabase(data[field.id]);
        else
            result[field.id] = formatString(data[field.id]) ?? '';
    });
    return result;
}

export const formatString = (value?: string) => {
    if (!value) return value;
    let formatted = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    //remove multiple spaces
    formatted = formatted.replace(/\s{2,}/g, ' ');
    //Capital case only on first letter
    formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase();
    return formatted;
}

export const mapDataResponse = (data: any, fields: Field[]) => {
    const result: any = {};
    fields.forEach(field => {
        if (field.type === FieldTypes.Date) {
            result[field.id] = data[field.id] ? getDateFromDatabaseToView(data[field.id]) : field.defaultValue;
        }
        else {
            result[field.id] = data[field.id] ?? field.defaultValue ?? '';
        }
    });
    return result;
}

export const currentTimestamp = () => {
    return new Timestamp(new Date().getTime() / 1000, 0);
}

export const viewData = (res: any, field: Field, useLabel?: boolean) => {
    const key = useLabel ? field.label : field.id;
    switch (field.type) {
        case FieldTypes.Currency:
            const suffix = field.customSuffix ?? "R$";
            return numberToCurrency(res[key], suffix);
        case FieldTypes.Date:
            return getDateFromDatabaseToView(res[key]);
        case FieldTypes.Checkbox:
            return res[key] ? "Sim" : "Não";
        default:
            return res[key];
    }
}


export const fixDecimals = (input: string | number) => {
    let value = input?.toString() ?? '';

    if (value === '') return value;

    if (value.slice(0, 1) === '.') {
        value = '0' + value;
    }

    if (!value.includes('.')) {
        value = value + '.00';
    }

    if (value.split('.')[1].length < 2) {
        value = value + '000';
    }

    value = value.slice(0, value.indexOf('.') + 3);

    return value;
}

export const formatRealToInput = (value: number) => {
    let str = value?.toString() ?? '';
    //remove . and replace , to .
    str = str.replace(/\./g, ',');
    return str;
}

export const formatRealToDatabase = (str: string): string => {
    // Remove all invalid characters except digits, commas, dots, and minus signs
    let sanitized = str.replace(/[^0-9,.-]/g, '');

    // Ensure the minus sign is only at the beginning
    sanitized = sanitized.replace(/-/g, '');
    if (str.includes('-')) sanitized = '-' + sanitized;

    // Replace commas with dots for decimal notation
    sanitized = sanitized.replace(/,/g, '.');

    // Ensure there's only one decimal separator
    const parts = sanitized.split('.');
    if (parts.length > 2) {
        sanitized = parts[0] + '.' + parts.slice(1).join('').replace(/\./g, '');
    }

    // Limit to two decimal places
    const [integerPart, decimalPart] = sanitized.split('.');
    sanitized = decimalPart ? `${integerPart}.${decimalPart.slice(0, 2)}` : integerPart;

    return sanitized;
};

export type SortDirection = "asc" | "desc";
export const dataSort = (data: any[], sortBy: string, sortDirection: SortDirection) => {
    const getRelativeString = (data: any): string => {
        let str = data.toDate?.().toLocaleDateString() ?? data;
        str = str.toString?.() ?? str;
        str = str.toLowerCase?.() ?? str;
        return str;
    }

    return data.sort((a: any, b: any) => {
        let _a = a[sortBy];
        let _b = b[sortBy];
        if (typeof _a === "number" && typeof _b === "number") {
            return sortDirection === "asc" ? _a - _b : _b - _a;
        }

        if (typeof _a === "boolean" && typeof _b === "boolean") {
            return sortDirection === "asc" ? _a ? -1 : 1 : _a ? 1 : -1;
        }

        _a = getRelativeString(a[sortBy]);
        _b = getRelativeString(b[sortBy]);
        if (sortDirection === "asc") {
            if (_a > _b) return 1;
            if (_a < _b) return -1;
            return 0;
        } else {
            if (_a > _b) return -1;
            if (_a < _b) return 1;
            return 0;
        }
    });
}

export const getSortIcon = (field: string, sortBy: string, SortDirection: SortDirection) => {
    if (sortBy !== field) {
        return <FontAwesomeIcon icon={faMinus} />;
    }
    if (SortDirection === "asc") {
        return <FontAwesomeIcon icon={faArrowUp} />;
    } else {
        return <FontAwesomeIcon icon={faArrowDown} />;
    }
}


export const dataSearch = (data: any[], search: string) => {
    const getRelativeString = (data: any): string => {
        if(!data.toDate) return data;
        let str = data.toDate().toLocaleDateString();
        str = str.toString();
        str = str.toLowerCase();
        return str;
    }

    const filterred = data.filter(d => {
        const n = getRelativeString(d.name).toLowerCase();
        const s = getRelativeString(search).toLowerCase();
        return n.includes(s)
    });

    return filterred;

}


export const timezoneOffSet = new Date().getTimezoneOffset() * 60 * 1000;

export const ExcelDateToJSDate = (date: number | string) => {
    const dateNumber = Number(date);


    if (isNaN(dateNumber) && typeof date === 'string') {
        const [day, month, year] = date.split('/');
        const final = new Date(Number(year), Number(month) - 1, Number(day));
        return final;
    }

    return new Date(Math.round(((dateNumber - 25569) * 86400 * 1000) + timezoneOffSet));
}


export const JSDateToExcelDate = (date: Date) => {
    return date.toLocaleDateString('pt-BR');
}

export const convertToExcelData = (res: any, field: Field) => {
    const value = res[field.id];
    switch (field.type) {
        case FieldTypes.Currency:
            return currencyToNumber(value);
        case FieldTypes.Date:
            const date = TimestampToDate(value);
            return JSDateToExcelDate(date);
        case FieldTypes.Checkbox:
            return value === "Sim" ? true : false;
        default:
            return value;
    }
}



export const revertExcelData = (res: any, field: Field) => {
    const value = res[field.label];
    switch (field.type) {
        case FieldTypes.Currency:
            return value as number;
        case FieldTypes.Date:
            return ExcelDateToJSDate(value);
        case FieldTypes.Checkbox:
            return value ? "Sim" : "Não";
        default:
            return value;
    }
}


export const getMonthYear = (date: Timestamp | Date) => {
    if (date instanceof Date) {
        const fullMonthName = date.toLocaleString('pt-BR', { month: 'long' });
        const capitalized = fullMonthName.charAt(0).toUpperCase() + fullMonthName.slice(1);
        const year = date.getFullYear();
        return capitalized + "/" + year;
    }

    const fullMonthName = date.toDate().toLocaleString('pt-BR', { month: 'long' });
    const year = date.toDate().getFullYear();
    return fullMonthName + "/" + year;
}

export const MonthYearToDate = (monthYear?: string) => {
    const dm = monthYear ?? getMonthYear(new Date());
    const [fullMonthName, year] = dm.split('/');
    const month = getMonthId(fullMonthName);
    const date = new Date(Number(year), month);
    return date;
}

export const getMonthId = (monthName: string) => {
    const allMonths = [];
    for (let i = 0; i < 12; i++) {
        const date = new Date(2021, i);
        const month = date.toLocaleString('pt-BR', { month: 'long' });
        allMonths.push(month);
    }

    return allMonths.indexOf(monthName.toLowerCase());
}



export const normalizeName = (name: any) => {
    //remove special characters
    const specialChars = /[^a-zA-Z0-9 ]/g;
    if (name.toString()) name = name.toString();
    if (name === "") return "";

    name = name.replace(specialChars, "");
    //replace spaces with _
    name = name.replace(/ /g, "");
    //convert to lower case
    return name.toLowerCase();
}

export const exportJsonToFile = (data: object, filename: string = "data.json") => {
    const jsonStr = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
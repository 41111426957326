import { ModalComponent, TModal } from 'templates/components/Modal';
import React, { useState } from 'react';


const ModalContext = React.createContext<{showModal: (modal: TModal) => void}>({
    showModal: () => { },
});

const useModal = () => React.useContext(ModalContext);

const ModalProvider = (props: { children: React.ReactNode }) => {
    const [modal, setModal] = useState<TModal | null>();

    const showModal = (modal: TModal) => setModal(modal);

    const cancelModal = () => {
        if (modal?.onCancel) {
            modal.onCancel();
        }
        setModal(null);
    }

    const confirmModal = () => {
        modal?.onConfirm();
        setModal(null);
    }

    return (
        <ModalContext.Provider value={{ showModal }}>
            {modal && <ModalComponent {...modal} onCancel={cancelModal} onConfirm={confirmModal} />}
            {props.children}
        </ModalContext.Provider>
    );
}



export { ModalProvider, useModal };

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/welltemplate.scss'
import ProviderHandler from 'handlers/ProviderHandler';
import { RouteHandler } from 'handlers/RouteHandler';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <ProviderHandler>
    <RouteHandler />
  </ProviderHandler>
);
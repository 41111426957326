import TopMenu from "templates/menu/TopMenu";
import SideMenu from "./menu/SideMenu";
import { useAuth } from "provider/AuthProvider";
import { useMemo } from "react";

type Props = {
    doLogout?: () => void;
    children?: React.ReactNode;
};

const DefaultTemplate = ({ children, doLogout }: Props) => {
    const auth = useAuth();
    const { Logout, userData } = auth;

    // Memoize user data to prevent unnecessary re-renders
    const user = useMemo(() => userData(), [userData]);

    return (
        <div className="main-wrapper">
            <TopMenu doLogout={doLogout || Logout} email={user?.email} />
            <aside className="sidebar-wrapper d-none d-lg-block">
                <SideMenu doLogout={doLogout || Logout} email={user?.email} />
            </aside>
            <main className="content-wrapper">
                {children}
            </main>
        </div>
    );
};

export default DefaultTemplate;
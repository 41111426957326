import React, { memo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { AuthProvider } from 'provider/AuthProvider';
import { ModalProvider } from 'provider/ModalProvider';

const ProviderHandler = ({ children }: { children: React.ReactNode }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ModalProvider>
                <AuthProvider>
                    {children}
                </AuthProvider>
            </ModalProvider>
        </LocalizationProvider>
    );
};

export default memo(ProviderHandler);

import { getMonthYear, MonthYearToDate, normalizeName } from "utils/utils";
import RequestHandler from "../RequestHandler";
import { ExpenseModel, ExpenseFields } from "../../dynamic-models/ExpenseModel";
import { Timestamp } from "firebase/firestore";

export type CategoryExpense = {
    name: string;
    totalMonth: number;
    lastPaidMonth: number;
    lastPaidMonthDate: string;
    averageMonth: number;
}

type CategoryExpenseList = {
    [key: string]: CategoryExpense;
}

const NUMBER_OF_PAST_MONTHS: number = 3;
const PAST_DATE_LIMIT: number = new Date(new Date().getFullYear(), new Date().getMonth() - NUMBER_OF_PAST_MONTHS, 1).getTime();
const CURRENT_MONTH: string = getMonthYear(new Date());


export class CategoryRequestHandler extends RequestHandler {
    constructor(endpoint: string = "") {
        super("expense");
    }

    override all = async (): Promise<any> => {
        const [data] = await this.getUserData();
        const endpointData = data[ExpenseModel.endpoint].data;
        const all = [] as any[];


        //Aggregating data by month
        const monthData = endpointData
            .reduce((acc: any, curr: any) => {
                const month = curr.monthref ? curr.monthref : getMonthYear(curr.date.toDate());
                if (!acc[month]) acc[month] = [];
                acc[month].push(curr);
                return acc;
            }, {});


        

        //Aggregating data by month/category
        const categoryData = Object.keys(monthData).reduce((acc: any, curr: string) => {
            if (!acc[curr]) acc[curr] = {};
            monthData[curr].forEach((item: any) => {
                const value = parseFloat(item.value);
                const category = item.category;
                if (!acc[curr][category]) acc[curr][category] = {
                    data: {},
                    totalValue: 0,
                    numberOfRecords: 0,
                    average: 0,
                };

                acc[curr][category].category = category;
                acc[curr][category].totalValue += value;
                acc[curr][category].numberOfRecords += 1;
                acc[curr][category].average = (acc[curr][category].totalValue / acc[curr][category].numberOfRecords).toFixed(2);;
                const key = normalizeName(item.description);
                if (!acc[curr][category].data[key]) {
                    acc[curr][category].data[key] = {
                        name: item.description,
                        totalValue: 0,
                        numberOfRecords: 0,
                        average: 0,
                        data: [],
                    };
                }

                acc[curr][category].data[key].name = item.description;
                acc[curr][category].data[key].totalValue = acc[curr][category].data[key].totalValue + value;
                acc[curr][category].data[key].numberOfRecords += 1;
                acc[curr][category].data[key].average = acc[curr][category].data[key].totalValue / acc[curr][category].data[key].numberOfRecords;
                acc[curr][category].data[key].data.push(item);
            });
            return acc;
        }, {});

        

        //Changing data to array result
        Object.keys(categoryData).forEach((month: string) => {
            const d = {
                month: MonthYearToDate(month),
                totalValue: 0,
                numberOfRecords: 0,
                average: 0,
                data: {} as any,
            }
            Object.keys(categoryData[month]).forEach((category: string) => {
                const item = categoryData[month][category];
                d.totalValue += item.totalValue;
                d.numberOfRecords += item.numberOfRecords;
                d.average = d.totalValue / d.numberOfRecords;

                d.data[category] = {
                    name: category,
                    totalValue: item.totalValue,
                    numberOfRecords: item.numberOfRecords,
                    average: item.average,
                    data: item.data,
                };
            });

            all.push(d);
        });


        //Sorting by month
        const res = all.sort((a: any, b: any) => {
            if (a.month.getTime() > b.month.getTime()) return -1;
            if (a.month.getTime() < b.month.getTime()) return 1;
            return 0;
        });

        return res;
    }
}
import ExpenseCategoryChart from "templates/components/chartjs/ExpenseCategoryChart";
import { Col, Row } from "react-bootstrap";
import NewExpenseWidget from "./widget/NewExpenseWidget";



const Dashboard = () => {
    return (
        <Row>
            <Col>
                <NewExpenseWidget />
                <ExpenseCategoryChart />
            </Col>
        </Row>
    )
}

export default Dashboard;
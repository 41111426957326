import { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useAlert } from "provider/AlertProvider";
import { useAuth } from "provider/AuthProvider";

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert()
    const auth = useAuth();

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const isValid = event.currentTarget.checkValidity();
        if (!isValid) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setIsLoading(true);
        try {
            await auth.Login(emailRef.current?.value, passwordRef.current?.value);
        }
        catch (error) {
            alert.showAlert({
                type: "error",
                message: "Erro ao fazer login!"
            });
            setIsLoading(false);
        }
    }

    const googleLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await auth.LoginWithGoogle();
        }
        catch (error) {
            alert.showAlert({
                type: "error",
                message: "Erro ao fazer login!"
            });
            setIsLoading(false);
        }
    }


    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <Button onClick={googleLogin} variant="primary" className="w-100" disabled={isLoading}>
                Login with google
            </Button> */}
            {/* <hr /> */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" required name="email" ref={emailRef} disabled={isLoading} />
                <Form.Control.Feedback type="invalid">
                    Por favor, informe um email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Senha</Form.Label>
                <Form.Control type="password" required name="password" ref={passwordRef} disabled={isLoading} />
                <Form.Control.Feedback type="invalid">
                    Por favor, informe uma senha.
                </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" variant="success" className="w-100" disabled={isLoading}>
                {isLoading ? "Carregando..." : "Entrar"}
            </Button>
            <NavLink to="/Register" className="btn btn-link w-100">Clique aqui para criar nova conta!</NavLink>
        </Form>

    )
}


export default Login;
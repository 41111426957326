import { InvestmentBalanceRequestHandler } from "handlers/request-handlers/InvestmentBalanceRequestHandler";
import { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Loading from "templates/components/Loading";
import { currencyFormater } from "utils/utils";

const InvestmentBalance = () => {
    const [queryData, setQueryData] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const service = new InvestmentBalanceRequestHandler();
        service.all().then((data) => {
            setQueryData(data);
            const totalValue = data.reduce((acc: any, item: any) => {
                return acc + item.balance;
            }, 0);
            setTotal(totalValue);
            setLoading(false);
        });
    }, []);
    

    if(loading) {
        return <Loading />
    }

    return <></>
    
    const classTotal = total < 0 ? "text-danger" : "text-success";

    return (<Card>
        <Card.Header>
            <Card.Title className="mb-0">Balanço</Card.Title>
            <Card.Subtitle className="text-muted">Saldo total: <span className={classTotal}> {currencyFormater.format(total)} </span></Card.Subtitle>
        </Card.Header>
        <Card.Body className="p-0">
            <Table striped bordered hover size="sm" className="m-0">
                <thead>
                    <tr>
                        <th scope="col">Banco</th>
                        <th scope="col">Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {queryData.map((item) => (
                        <tr key={item.name}>
                            <td>{item.name}</td>
                            <td>{currencyFormater.format(item.balance)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Card.Body>
    </Card>
    );
}

export default InvestmentBalance;

import { SortDirection, currencyFormater, dataSearch, dataSort } from 'utils/utils';
import SortIcon from 'templates/components/SortIcon';
import TablePlaceHolder from 'templates/components/TablePlaceholder';
import React, { useEffect } from 'react';
import { Card, ListGroup, Table } from 'react-bootstrap';
import { MonthlyExpense, MonthlyRequestHandler } from 'handlers/request-handlers/MonthlyRequestHandler';


const MonthlyExpenses = () => {
    const category = "Mensal";
    const [search, setSearch] = React.useState<string>("");
    const [queryData, setQueryData] = React.useState<{
        [key: string]: any[];
    }>({});
    const [sortBy, setSortBy] = React.useState<string>("name");
    const [sortOrder, setSortOrder] = React.useState<SortDirection>("asc");
    const [loading, setLoading] = React.useState<boolean>(true);


    
    const headers = [
        { field: "name", label: "Nome" },
        { field: "paidCurrentMonth", label: "Pago" },
        { field: "totalCurrentMonth", label: "Total Atual" },
        { field: "totalLastMonth", label: "Último Mês" },
        { field: "average", label: "Média" },
        { field: "lastPaidValue", label: "Último" },
        { field: "lastPaidDate", label: "Data" },
        { field: "paymentMethod", label: "Tipo" },
        { field: "percentage", label: "%" }
    ];

    const expenseRender = (expense: MonthlyExpense, index: number) => {
        const paidClass = expense.paidCurrentMonth ? "cell-success" : "cell-danger";
        return (
            <tr key={index}>
                <td>{expense.name}</td>
                <td className={paidClass}>{expense.paidCurrentMonth ? "Sim" : "Não"}</td>
                <td>{currencyFormater.format(expense.totalCurrentMonth)}</td>
                <td>{currencyFormater.format(expense.totalLastMonth)}</td>
                <td>{currencyFormater.format(expense.average)}</td>
                <td>{currencyFormater.format(expense.lastPaidValue)}</td>
                <td>{expense.lastPaidDate.toDate?.().toLocaleDateString() ?? ""}</td>
                <td>{expense.paymentMethod}</td>
                <td>{expense.percentage.toFixed(2) + "%"}</td>
            </tr>
        )
    }

    useEffect(() => {
        const service = new MonthlyRequestHandler()
        service.all().then((response: any) => {
            setQueryData(response as any);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <TablePlaceHolder rows={10} columns={9} />
    }

    const handleSort = (field: string) => {
        if (sortBy === field) {
            if (sortOrder === "asc") {
                setSortOrder("desc");
            } else {
                setSortOrder("asc");
            }
        } else {
            setSortBy(field);
        }
    }

    const data = queryData[category] || [];
    const sortedData = dataSort(dataSearch(data, search), sortBy, sortOrder);
    const listData = sortedData.map(expenseRender);

    return (
        <Card>
            <Card.Header>
                <Card.Title>Despesas Mensais</Card.Title>
                
                <Card.Subtitle className='mtop-1'>Total: <span className='text-success'>{currencyFormater.format(data.reduce((acc, curr) => { return curr.oldExpense ? acc : acc + curr.lastPaidValue }, 0))}</span></Card.Subtitle>
                <Card.Subtitle className='mtop-1'>Pendente: <span className='text-danger'>{currencyFormater.format(data.reduce((acc, curr) => { return curr.paidCurrentMonth === false && curr.oldExpense === false ? acc + curr.lastPaidValue : acc }, 0))}</span></Card.Subtitle>
            </Card.Header>
            <Card.Body className="p-0">
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <input type="search" style={{ borderColor: "transparent" }} className="form-control" placeholder="Pesquisar..." value={search} onChange={e => setSearch(e.target.value)} />
                    </ListGroup.Item>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                {
                                    headers.map((header, index) => {
                                        return (
                                            <th key={index} onClick={() => handleSort(header.field)}>{header.label} <SortIcon field={header.field} sortBy={sortBy} SortDirection={sortOrder} /></th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listData.length === 0 &&
                                <tr>
                                    <td colSpan={8} className="text-center">Nenhum registro encontrado</td>
                                </tr>

                            }
                            {
                                listData.map((expense) => {
                                    return expense;
                                })
                            }
                        </tbody>
                    </Table>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};



export default MonthlyExpenses;
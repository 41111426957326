import { useNavigate, useSearchParams } from "react-router-dom";
import DynamicTable, { DynamicTableProps } from "./DynamicTable";
import { IModelSetup, generateFormFields, generateTableFields, getDefaultService } from "infrastructure/ModelStructure";
import { DynamicEndpoints } from "./DynamicRoute";

const DynamicList = (props: IModelSetup) => {
    const [searchParams] = useSearchParams();
    const service = props.service ?? getDefaultService(props.endpoint);
    const navigate = useNavigate();

    const dtProps: DynamicTableProps = {
        title: props.plural,
        article: props.article,
        createLink: props.defaultPath + DynamicEndpoints.new,
        service: service,
        searchQuery: searchParams.get("search"),
        fields: generateTableFields(props.fields),
        onRowClick: (data: any) => navigate(`${props.defaultPath}/${data.id}`),
        currentPage: searchParams.get("page"),
        excelActions: {
            service: service,
            createAction: service.create,
            excelFileName: props.title,
            fields: generateFormFields(props.fields)
        },
        createOnList: props.createOnList ? props : undefined,
        orderBy: props.orderBy,
        orderDirection: props.orderDirection,
        model: props
    }

    return (
        <>
            {props.widgets?.map((element, key) => {
                return <div key={key} className="mb-3">{element}</div>
            })}
            <DynamicTable {...dtProps} />
        </>
    );
}

export default DynamicList;
import { FieldTypes, IModelSetup } from "../infrastructure/ModelStructure";
import { getMonthYear, MonthYearToDate } from "utils/utils";
import * as fa from "@fortawesome/free-solid-svg-icons";
import RequestHandler from "../handlers/RequestHandler";

const endpoint = "expense";

export const ExpenseFields = {
    Id: "id",
    Date: "date",
    MonthReference: "monthref",
    Value: "value",
    Type: "type",
    Category: "category",
    Description: "description",
} as const;

const CategoryAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("category_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            return item[ExpenseFields.Category]
        })
        return optionList;
    })
}


const TypeAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("type_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            return item[ExpenseFields.Type]
        })
        return optionList;
    })
}

const DescriptionAutoComplete = () => {
    return (new RequestHandler(endpoint)).all("description_autocomplete").then((list) => {
        const optionList = list.map((item: any) => {
            if(item[ExpenseFields.Category] !== "Mensal") return null;
            return item[ExpenseFields.Description]
        })
        return optionList;
    })
}


const MonthReferenceOptions = (requester: string, value?: string) => {
    const v = value ? value : getMonthYear(new Date());
    const date = MonthYearToDate(v);
    const dateList = [];
    
    for(let i = -2; i <= 2; i++) {
        const m = date.getMonth() + i;
        const y = date.getFullYear();
        const d = new Date(y, m, 1);
        dateList.push(getMonthYear(d));
    }
    return Promise.resolve(dateList);
}


export const ExpenseModel: IModelSetup = {
    endpoint: endpoint,
    title: "Despesa",
    plural: "Despesas",
    article: "a",
    defaultPath: process.env.ROUTE_EXPENSES ?? "/Expenses",
    orderBy: ExpenseFields.Date,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: fa.faMoneyBill,
    fields: {
        [ExpenseFields.Date]: {
            question: 'Qual a data da despesa?',
            label: "Data",
            id: ExpenseFields.Date,
            type: FieldTypes.Date,
            required: true,
            defaultValue: new Date(),
            fieldSize: 4,
        },
        [ExpenseFields.MonthReference]: {
            question: 'Qual o mês de referência da despesa?',
            label: "Mês de Referência",
            id: ExpenseFields.MonthReference,
            type: FieldTypes.Select,
            required: false,
            defaultValue: getMonthYear(new Date()),
            fieldSize: 4,
            selectList: MonthReferenceOptions,
        },
        [ExpenseFields.Value]: {
            question: 'Qual o valor da despesa?',
            label: "Valor",
            id: ExpenseFields.Value,
            type: FieldTypes.Currency,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Category]: {
            question: 'Qual a categoria da despesa?',
            label: "Categoria",
            id: ExpenseFields.Category,
            type: FieldTypes.AutoComplete,
            autocompleteList: CategoryAutoComplete,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Type]: {
            question: 'Como a despesa foi paga?',
            label: "Pagamento",
            id: ExpenseFields.Type,
            type: FieldTypes.AutoComplete,
            autocompleteList: TypeAutoComplete,
            required: true,
            fieldSize: 4,
        },
        [ExpenseFields.Description]: {
            question: 'Qual a descrição da despesa?',
            label: "Descrição",
            id: ExpenseFields.Description,
            type: FieldTypes.AutoComplete,
            autocompleteList: DescriptionAutoComplete,
            required: true,
            fieldSize: 6,
        },
    }
}
import DynamicTextInput from "./DynamicTextInput";
import DynamicCheckboxInput from "./DynamicCheckboxInput";
import DynamicCurrencyInput from "./DynamicCurrencyInput";
import DynamicAutocomplete from "./DynamicAutocomplete";
import DynamicDateInput from "./DynamicDateInput";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Col } from "react-bootstrap";
import { Field, FieldTypes } from "infrastructure/ModelStructure";
import DynamicSelect from "./DynamicSelect";
import React from "react";

export type DynamicInputProps = {
    field: Field;
    value?: any;
    disabled?: boolean;
    onChange: (field: string, value: any) => void;
    size?: number;
    errors?: string;
    noLabel?: boolean;
    small?: boolean;
    enterKey?: () => void;
    autoFocus?: boolean;
    removeEmptySelection?: boolean;
}

const inputOptions = {
    [FieldTypes.Checkbox]: DynamicCheckboxInput,
    [FieldTypes.Currency]: DynamicCurrencyInput,
    [FieldTypes.AutoComplete]: DynamicAutocomplete,
    [FieldTypes.Date]: DynamicDateInput,
    [FieldTypes.Select]: DynamicSelect,
    [FieldTypes.String]: DynamicTextInput,
    [FieldTypes.Number]: DynamicTextInput,
}


const DynamicElement = (props: DynamicInputProps) => {
    const Element = inputOptions[props.field.type];
    if (Element) return <Element {...props} />
    return <DynamicTextInput {...props} />;
}

const ErrorMessage = (props: { show: boolean }) => {
    if (!props.show) return null;
    return (
        <FormHelperText id="component-helper-text" error>
            {`Por favor, informe um valor para este campo.`}
        </FormHelperText>
    )
}

const Label = (props: { field: Field, show: boolean, error?: boolean }) => {
    if (!props.show) return null;
    return (
        <InputLabel htmlFor={props.field.id} error={props.error}>{props.field.label}</InputLabel>
    )

}

const DynamicInput = (props: DynamicInputProps) => {
    const error = props.errors !== undefined && props.errors !== "";
    const show = props.errors !== undefined && props.errors !== "" && !props.noLabel;
    return (
        <Col xs={props.size ?? 12}>
            <Label field={props.field} show={!props.noLabel} error={error} />
            <FormControl
                fullWidth
                >
                <DynamicElement {...props} />
                <ErrorMessage show={show} />
            </FormControl>
        </Col>
    )
}




export default DynamicInput;
import { useMemo, useState } from "react";
import { DynamicInputProps } from "./DynamicInput"
import { MenuItem, Select } from "@mui/material"

export interface IOption {
  label: string;
}

const DynamicSelect = (props: DynamicInputProps) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<IOption[]>([]);
  const hasError = props.errors !== undefined && props.errors !== "";


  const OnChange = (event: any, value: any) => {
    props.onChange(props.field.id, value)
  }

  const loadOptions = () => {
    setLoading(true);
    if (!props.field.selectList) {
      setLoading(false);
    }
    else {
      const requester = props.field.id + " - DynamicSelect";
      props.field.selectList(requester, props.value).then((optionList: string[]) => {
        //remove duplicates and empty strings from optionList
        const uniqueOptions = optionList.filter((v, i, a) => a.indexOf(v) === i && v !== "" && v).map((o) => {
          return { label: o }
        });

        setOptions(uniqueOptions);
        setLoading(false);
      })
    }
  }

  useMemo(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field.id])

  if (loading) return <></>;


  return <Select 
    value={props.value} 
    onChange={(e) => OnChange(e, e.target.value)} 
    autoFocus={props.autoFocus} 
    size={props.small ? "small" : "medium"}
  >
    {props.field.removeEmptySelection ? null : <MenuItem value={""}>Selecione</MenuItem> }
    {options.map((option, index) => {
      return <MenuItem key={index} value={option.label}>{option.label}</MenuItem>
    }
    )}
  </Select>
}

export default DynamicSelect

import { getMonthYear, MonthYearToDate, normalizeName } from "utils/utils";
import RequestHandler from "../RequestHandler";
import { BankAccountModel } from "dynamic-models/BankAccountModel";

// Define a type for bank balance entries
interface BankBalance {
    name: string;
    balance: number;
    date: Date;
}

export class BankBalanceRequestHandler extends RequestHandler {
    constructor(endpoint: string = "") {
        super(BankAccountModel.endpoint);
    }

    override all = async (): Promise<BankBalance[]> => {
        try {
            const [data] = await this.getUserData();
            const endpointData = data[BankAccountModel.endpoint]?.data || [];

            const balancesMap: Record<string, BankBalance> = {};

            for (const entry of endpointData) {
                const entryDate = entry.date instanceof Date ? entry.date : new Date(entry.date);

                if (!balancesMap[entry.name] || balancesMap[entry.name].date < entryDate) {
                    balancesMap[entry.name] = {
                        name: entry.name,
                        balance: entry.balance,
                        date: entryDate,
                    };
                }
            }

            return Object.values(balancesMap).sort((a, b) => b.date.getTime() - a.date.getTime());
        } catch (error) {
            console.error("Error fetching bank balances:", error);
            throw new Error("Failed to fetch bank balances.");
        }
    };
}

// import SearchBox from '../../Components/SearchBox';
import logo from 'assets/image/gp_400px.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Nav, Navbar } from 'react-bootstrap';
import MenuList from 'templates/menu/MenuList';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import {  ListItem, ListItemText } from '@mui/material';

type Props = {
    doLogout?: () => void;
    email?: string | null | undefined;
    isLoading?: boolean;
};

const TopMenu = ({ doLogout, email }: Props) => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand className='d-lg-none'>
                <img src={logo} alt="GoldPocket" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav " className='m-2' />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <MenuList className='nav-link menu-item-collapsed d-lg-none' />
                </Nav>
                <div className="top-menu-user-info">
                    <ListItem
                        role="menuItem"
                        secondaryAction={
                            <Button className="btn-logout" variant='link' onClick={doLogout}>
                                <FontAwesomeIcon icon={faSignOut} className="p-1" />
                            </Button>
                        }>
                        <ListItemText style={{ marginRight: "1em" }}>
                            Olá, {email}
                        </ListItemText>
                    </ListItem>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default TopMenu;
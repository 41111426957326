import InvestmentBalance from "view/private/widget/InvestmentBalance";
import { FieldTypes, IModelSetup } from "../infrastructure/ModelStructure";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

const endpoint = "investments";

export const InvestmentsFields = {
    Id: "id",
    Date: "month",
    Balance: "balance",
    Name: "name",
} as const;

const InvestmentWidgets = [<InvestmentBalance key="investment-balance" />];

export const InvestmentsModel: IModelSetup = {
    endpoint: endpoint,
    title: "Investimento",
    plural: "Investimentos",
    article: "o",
    defaultPath: process.env.ROUTE_EXPENSES ?? "/Investments",
    orderBy: InvestmentsFields.Date,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: faCoins,
    widgets: InvestmentWidgets,
    idGen: (data) => {
        const date = new Date(data[InvestmentsFields.Date] ?? new Date());
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        const name = data[InvestmentsFields.Name] ?? "Unknown";
        return `${formattedDate}-${name}`;
    },
    fields: {
        [InvestmentsFields.Date]: {
            question: "Qual a data do investimento?",
            label: "Data",
            id: InvestmentsFields.Date,
            type: FieldTypes.Date,
            defaultValue: new Date().toISOString().split("T")[0],
            required: true,
            fieldSize: 4,
        },
        [InvestmentsFields.Balance]: {
            question: "Qual o valor atual do investimento?",
            label: "Balanço",
            id: InvestmentsFields.Balance,
            type: FieldTypes.Currency,
            required: true,
            fieldSize: 4,
        },
        [InvestmentsFields.Name]: {
            question: "Qual o nome do investimento?",
            label: "Nome",
            id: InvestmentsFields.Name,
            type: FieldTypes.String,
            required: true,
            defaultValue: "",
            fieldSize: 4,
        },
    },
};

import { InvestmentsModel } from "dynamic-models/InvestmentsModel";
import RequestHandler from "../RequestHandler";
import { ContributionModel } from "dynamic-models/ContributionModel";
import { getMonthYear } from "utils/utils";


export class InvestmentBalanceRequestHandler extends RequestHandler {
    constructor(endpoint: string = "") {
        super(ContributionModel.endpoint);
    }

    override all = async (): Promise<any> => {
        const [data] = await this.getUserData();
        const contribution = data[ContributionModel.endpoint].data;
        const investments = data[InvestmentsModel.endpoint].data;

        
        

        const conts = contribution.reduce((acc: any, curr: any) => {
            const monthYat = getMonthYear(curr.date);
            if(!acc[monthYat]) acc[monthYat] = {
                month: monthYat,
                total: 0,
                data: [],
            };

            acc[monthYat].data.push(curr);
            acc[monthYat].total += parseFloat(curr.value);
            return acc;
        }
        , {});

        const totalContsValue = Object.values(conts).reduce((acc: number, curr: any) => {
            return acc + curr.total;
        }, 0);
        const totalContribution = {
            total: totalContsValue,
            data: conts,
        }

        
        

        const invs = investments.reduce((acc: any, curr: any) => {
            const monthYat = getMonthYear(curr.month);
            if(!acc[monthYat]) acc[monthYat] = {
                month: monthYat,
                total: 0,
                data: {} as any,
            };
            

            if(!acc[monthYat].data[curr.name]) {
                acc[monthYat].data[curr.name] = {
                    name: curr.name,
                    balance: curr.balance,
                    date: curr.month.toDate(),
                }
            } else {
                if (acc[monthYat].data[curr.name].date < curr.month.toDate()) {
                    acc[monthYat].data[curr.name].balance = curr.balance;
                    acc[monthYat].data[curr.name].date = curr.month.toDate();
                }
            }
            return acc;
        }
        , {});

        const res = {} as any;

        Object.keys(invs).forEach((key) => {
            
            const inv = invs[key];
            const contMonth = conts[key];
            const name = inv.month;
            const iList = Object.keys(inv.data);
            const iListTotal = iList.reduce((acc: number, curr: any) => {
                return acc + parseFloat(inv.data[curr].balance);
            }, 0);
            const iListData = Object.values(inv.data);
            const iListDataTotal = iListData.reduce((acc: number, curr: any) => {
                return acc + parseFloat(curr.balance);
            }, 0);
            const iListDataDate = iListData.reduce((acc: Date, curr: any) => {
                if (acc < curr.date) {
                    return curr.date;
                }
                return acc;
            }, new Date(0));
            



            res[key] = {
                month: inv.month,
                total: iListTotal,
                data: iListData,
                contribution: contMonth.total,
                contributionData: contMonth.data,
            }
        });

        
            // const monthYear = 
        // const res = endpointData.reduce((acc: any, curr: any) => {
        //     if (!acc[curr.name]) {
        //         acc[curr.name] = {
        //             name: curr.name,
        //             balance: curr.balance,
        //             date: curr.date.toDate(),
        //         }
        //     }

        //     if (acc[curr.name].date < curr.date.toDate()) {
        //         acc[curr.name].balance = curr.balance;
        //         acc[curr.name].date = curr.date.toDate();
        //     }

        //     return acc;
        // }, {});

        const results = [] as any[];
        // Object.keys(res).forEach((key) => {
        //     results.push(res[key]);
        // });

        return results;
    }
}
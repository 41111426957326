import { FieldTypes, IModelSetup } from "infrastructure/ModelStructure";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import RequestHandler from "handlers/RequestHandler";

const endpoint = "invoice";

export const InvoiceFields = {
    Id: "id",
    ReleaseDate: "release_date",
    ExpectedReceiveDate: "expected_receive_date",
    ExpectedValue: "value",
    Description: "description",
    Status: "status",
    PaidValue: "paid_value",
    BrValue: "br_value",
} as const;

// Função reutilizável para buscar lista de descrições
const fetchDescriptionList = async () => {
    const list = await new RequestHandler(endpoint).all();
    return list.map((item: any) => item[InvoiceFields.Description]);
};

export const InvoiceModel: IModelSetup = {
    endpoint: endpoint,
    title: "Nota Dólar",
    plural: "Notas Dólar",
    article: "a",
    defaultPath: "/Invoices",
    orderBy: InvoiceFields.ReleaseDate,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: faFileInvoice,
    fields: {
        [InvoiceFields.ReleaseDate]: {
            question: "Qual a data da nota?",
            label: "Emissão",
            id: InvoiceFields.ReleaseDate,
            type: FieldTypes.Date,
            required: true,
            defaultValue: new Date().toISOString().split("T")[0], // Formato "YYYY-MM-DD"
            fieldSize: 3,
        },
        [InvoiceFields.ExpectedReceiveDate]: {
            question: "Qual a data esperada de pagamento?",
            label: "Expectativa",
            id: InvoiceFields.ExpectedReceiveDate,
            type: FieldTypes.Date,
            required: true,
            defaultValue: new Date(new Date().setDate(new Date().getDate() + 35)).toISOString().split("T")[0],
            fieldSize: 3,
        },
        [InvoiceFields.ExpectedValue]: {
            question: "Qual o valor da nota?",
            label: "Valor",
            id: InvoiceFields.ExpectedValue,
            type: FieldTypes.Currency,
            customSuffix: "$",
            required: true,
            fieldSize: 3,
        },
        [InvoiceFields.Status]: {
            question: "Qual o status da nota?",
            label: "Status",
            id: InvoiceFields.Status,
            type: FieldTypes.Select,
            defaultValue: "Pendente",
            fieldSize: 3,
            required: true,
            removeEmptySelection: true,
            selectList: () => Promise.resolve(["Pendente", "Pago"]),
        },
        [InvoiceFields.Description]: {
            question: "Qual a descrição da nota?",
            label: "Descrição",
            id: InvoiceFields.Description,
            type: FieldTypes.AutoComplete,
            required: true,
            autocompleteList: fetchDescriptionList,
            fieldSize: 5,
        },
        [InvoiceFields.BrValue]: {
            question: "Qual o valor da nota em reais?",
            label: "Valor R$",
            id: InvoiceFields.BrValue,
            type: FieldTypes.Currency,
            customSuffix: "R$",
            fieldSize: 3,
        },
        [InvoiceFields.PaidValue]: {
            question: "Qual o valor pago?",
            label: "Valor pago",
            id: InvoiceFields.PaidValue,
            type: FieldTypes.Currency,
            fieldSize: 3,
        },
    },
};

import { FieldTypes, IModelSetup } from "../infrastructure/ModelStructure";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";

const endpoint = "contribution";

export const ContributionFields = {
    Id: "id",
    Date: "date",
    Value: "value",
    Name: "name",
} as const;

export const ContributionModel: IModelSetup = {
    endpoint: endpoint,
    title: "Aporte",
    plural: "Aportes",
    article: "o",
    defaultPath: process.env.ROUTE_EXPENSES ?? "/Contributions",
    orderBy: ContributionFields.Date,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: faBagShopping,
    fields: {
        [ContributionFields.Date]: {
            question: 'Qual a data do aporte?',
            label: "Data",
            id: ContributionFields.Date,
            type: FieldTypes.Date,
            defaultValue: new Date().toISOString().split("T")[0], // Melhor padrão para data
            required: true,
            fieldSize: 4,
        },
        [ContributionFields.Value]: {
            question: 'Qual o valor do aporte?',
            label: "Valor",
            id: ContributionFields.Value,
            type: FieldTypes.Currency,
            required: true,
            fieldSize: 4,
        },
        [ContributionFields.Name]: {
            question: 'Qual nome do aporte?',
            label: "Nome",
            id: ContributionFields.Name,
            type: FieldTypes.String,
            required: true,
            defaultValue: "",
            fieldSize: 4,
        },
    }
};

import { BankBalanceRequestHandler } from "handlers/request-handlers/BankBalanceRequestHandler";
import { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Loading from "templates/components/Loading";
import { currencyFormater } from "utils/utils";


// Define an interface for the bank data
interface BankData {
    name: string;
    balance: number;
}

const BankBalance = () => {
    const [queryData, setQueryData] = useState<BankData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const service = new BankBalanceRequestHandler();
        const controller = new AbortController();
        const signal = controller.signal;

        setLoading(true);
        service.all()
            .then((data: BankData[]) => {
                if (!signal.aborted) {
                    setQueryData(data);
                    setTotal(data.reduce((acc, item) => acc + item.balance, 0));
                }
            })
            .catch((err) => {
                if (!signal.aborted) {
                    console.error("Error fetching bank balance:", err);
                    setError("Failed to fetch data.");
                }
            })
            .finally(() => {
                if (!signal.aborted) {
                    setLoading(false);
                }
            });

        return () => controller.abort(); // Cleanup function
    }, []);

    if (loading) return <Loading />;
    if (error) return <div className="text-danger">{error}</div>;

    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0">Balanço Bancário</Card.Title>
                <Card.Subtitle className="text-muted">
                    Saldo total:{" "}
                    <span className={total < 0 ? "text-danger" : "text-success"}>
                        {currencyFormater.format(total)}
                    </span>
                </Card.Subtitle>
            </Card.Header>
            <Card.Body className="p-0">
                {queryData.length > 0 ? (
                    <Table striped bordered hover size="sm" className="m-0">
                        <thead>
                            <tr>
                                <th scope="col">Banco</th>
                                <th scope="col">Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {queryData.map(({ name, balance }) => (
                                <tr key={name}>
                                    <td>{name}</td>
                                    <td>{currencyFormater.format(balance)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="text-center p-3 text-muted">Nenhum dado disponível.</div>
                )}
            </Card.Body>
        </Card>
    );
};

export default BankBalance;

import { FieldTypes, IModelSetup } from "../infrastructure/ModelStructure";
import * as fa from "@fortawesome/free-solid-svg-icons";
import BankBalance from "view/private/widget/BankBalance";

const endpoint = "bankaccount";

export enum BankAccountFields {
    Id = 'id',
    Date = 'date',
    Name = 'name',
    Balance = 'balance',
}

const date = new Date();
date.setHours(0, 0, 0, 0);

export const BankAccountModel: IModelSetup = {
    endpoint: endpoint,
    title: "Balanço Bancário",
    plural: "Balanço Bancário",
    article: "o",
    defaultPath: process.env.ROUTE_EXPENSES ?? "/BankAccounts",
    orderBy: BankAccountFields.Date,
    orderDirection: "desc",
    createOnList: true,
    isMenu: true,
    menuIcon: fa.faBank,
    disableUpdate: true,
    widgets: [
        <BankBalance />
    ],
    idGen: (data) => {
        const date = new Date(data[BankAccountFields.Date] ?? new Date());
        const name = data[BankAccountFields.Name] ?? "Banco";
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${name}`;
    },
    fields: {
        [BankAccountFields.Date]: {
            question: 'Qual a data de referencia?',
            label: "Data",
            id: BankAccountFields.Date,
            type: FieldTypes.Date,
            required: true,
            defaultValue: date,
            fieldSize: 4,
        },
        [BankAccountFields.Name]: {
            question: 'Qual o nome do banco?',
            label: "Nome",
            id: BankAccountFields.Name,
            type: FieldTypes.String,
            required: true,
            defaultValue: "",
            fieldSize: 4,
        },
        [BankAccountFields.Balance]: {
            question: 'Qual o balanço da conta?',
            label: "Balanço",
            id: BankAccountFields.Balance,
            type: FieldTypes.Currency,
            required: true,
            fieldSize: 4,
        },
    }
}
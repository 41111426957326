import { Button, Stack } from "@mui/material"
import { ExpenseModel } from "dynamic-models/ExpenseModel"
import RequestHandler from "handlers/RequestHandler"
import { getFormData } from "infrastructure/ModelStructure"
import { useAlert } from "provider/AlertProvider"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { DynamicForm, DynamicFormProps } from "templates/components/dynamic/DynamicForm"
import Loading from "templates/components/Loading"

const PAYMENT_COOKIE_NAME = "gp_payment_default";
const CATEGORY_COOKIE_NAME = "gp_category_default";
const NewExpenseWidget = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const alert = useAlert()
    const [nullReturn, setNullReturn] = useState<boolean>(false);

    const [paymentMethodState, setPaymentMethodState] = useState<string>("");
    const [categoryState, setCategoryState] = useState<string>("");
    const [paymentMethodList, setPaymentMethodList] = useState<string[]>([])
    const [categoryList, setCategoryList] = useState<string[]>([]);
    const [data, setData] = useState(getFormData(ExpenseModel.fields));


    useEffect(() => {
        const service = new RequestHandler(ExpenseModel.endpoint);
        service.all().then((response: any) => {
            if(!response || response.length === 0) {
                setNullReturn(true);
                return;
            }
            const paymentList = response.reduce((acc: any, curr: any) => {
                if (!acc.includes(curr.type)) {
                    acc.push(curr.type);
                }
                return acc;
            }, []).sort((a: string, b: string) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            }
            );

            const categoryList = response.reduce((acc: any, curr: any) => {
                if (!acc.includes(curr.category)) {
                    acc.push(curr.category);
                }
                return acc;
            }
                , []).sort((a: string, b: string) => {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                }
                );;

            setPaymentMethodList(paymentList);
            const currentPaymentMethod = localStorage.getItem(PAYMENT_COOKIE_NAME) ?? paymentList[0];
            setPaymentMethod(currentPaymentMethod);

            setCategoryList(categoryList);
            const currentCategory = localStorage.getItem(CATEGORY_COOKIE_NAME) ?? categoryList[0];
            setCategory(currentCategory);

            setLoading(false);
        });
    }, [])



    const setPaymentMethod = (method: string) => {
        setPaymentMethodState(method);
        localStorage.setItem(PAYMENT_COOKIE_NAME, method);
    }

    const setCategory = (category: string) => {
        setCategoryState(category);
        localStorage.setItem(CATEGORY_COOKIE_NAME, category);
    }


    const ViewPaymentMethod = () => {
        return <Stack spacing={2} direction="row" flexWrap={"wrap"}>
            {paymentMethodList.map((item, index) => {
                if (item === paymentMethodState) {
                    return <Button key={index} variant="contained" size="small" className="m-1" onClick={() => setPaymentMethod(item)}>{item}</Button>
                }
                return <Button key={index} variant="outlined" size="small" className="m-1" onClick={() => setPaymentMethod(item)}>{item}</Button>
            })}
        </Stack>
    }

    const ViewCategory = () => {
        return <Stack spacing={2} direction="row" flexWrap={"wrap"}>
            {categoryList.map((item, index) => {
                if (item === categoryState) {
                    return <Button key={index} variant="contained" size="small" className="m-1" onClick={() => setCategory(item)}>{item}</Button>
                }
                return <Button key={index} variant="outlined" size="small" className="m-1" onClick={() => setCategory(item)}>{item}</Button>
            })}
        </Stack>
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const fdata = {
            ...data,
            category: categoryState,
            type: paymentMethodState,
        }

        const formData = getFormData(ExpenseModel.fields, fdata);


        const service = new RequestHandler(ExpenseModel.endpoint);
        service.create(formData).then((response: any) => {
            setData(getFormData(ExpenseModel.fields));
            alert.showAlert({
                type: "success",
                message: `Despesa criada com sucesso.`
            });
        }).catch((e: any) => {
            console.error(e);
            alert.showAlert({
                type: "error",
                message: `Não foi possível criar despesa.`
            });
        }).finally(() => {
            setLoading(false);
        })
    }


    if(nullReturn) {
        return <></>
    }

    if (loading) {
        return <Loading />
    }

    const model = {
        ...ExpenseModel
    }
    model.fields = {
        [ExpenseModel.fields.description.id]: ExpenseModel.fields.description,
        [ExpenseModel.fields.value.id]: ExpenseModel.fields.value,
        [ExpenseModel.fields.date.id]: ExpenseModel.fields.date,
    }

    const form: DynamicFormProps = {
        model: model,
        title: `Nova despesa rápida`,
        actionLabel: "Criar!",
        loading: loading,
        handleSubmit: handleSubmit,
        onChangeField: setData,
        data: data,
        size: 12,
        removeBackButton: true,
        fields: {
            [ExpenseModel.fields.description.id]: ExpenseModel.fields.description,
            [ExpenseModel.fields.value.id]: ExpenseModel.fields.value,
            [ExpenseModel.fields.date.id]: ExpenseModel.fields.date,
        },
    }

    return <DynamicForm {...form}>
        <Form.Group className="mb-3" controlId="type">
            <Form.Label>Tipo</Form.Label>
            <ViewPaymentMethod />
        </Form.Group>
        <Form.Group className="mb-3" controlId="category">
            <Form.Label>Categoria</Form.Label>
            <ViewCategory />
        </Form.Group>
    </DynamicForm>

}

export default NewExpenseWidget
import { auth, firestore } from "config/firebase-config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import databaseDataHandler from "handlers/DatabaseHandler";
import { localStorageHandler } from "handlers/StorageHandler";

const USE_LOCAL_STORAGE: boolean = true;
const IS_TEST: boolean = window.location.hostname.includes("localhost");
const APP_NAME: string = IS_TEST ? "testgoldpocket" : "goldpocket";

class RequestHandler {
    protected endpoint: string;
    protected useLocalStorage: boolean = USE_LOCAL_STORAGE;
    private appName: string;
    protected localStorageHandler: localStorageHandler;
    protected dataHandler: databaseDataHandler;

    constructor(endpoint: string) {
        this.dataHandler = new databaseDataHandler(endpoint);
        this.appName = APP_NAME;
        this.endpoint = endpoint;
        this.localStorageHandler = new localStorageHandler(this.appName, this.getUserId());
    }

    public getUserId = () => {
        return auth.currentUser?.uid ?? "0";
    }

    public refreshData = (requester: string) : Promise<any[]> => {
        this.localStorageHandler.clearStoredData();
        return this.all(requester);
    }

    public getUserData = async () => {
        const docRef = doc(firestore, this.appName, this.getUserId());
        const docSnap = await getDoc(docRef);
        const data: any = this.dataHandler.retrieveUserData(docSnap.data());
        return [data, docSnap, docRef];
    }

    public all = async (option?: string): Promise<any[]> => {
        if (this.useLocalStorage && this.localStorageHandler.isStored()) {
            const data = this.localStorageHandler.getStoredData();
            return this.dataHandler.retrieveEndpointData(data);
        }
        else {
            const [data] = await this.getUserData();
            this.localStorageHandler.storeData(data);
            return this.dataHandler.retrieveEndpointData(data);
        }
    }

    public get = async (id: string, requester: string) => {
        const list = await this.all(requester);
        const item = list.find((i: any) => i.id.toString() === id);
        if (!item && this.useLocalStorage) {
            const refreshedList = await this.refreshData(requester);
            return refreshedList.find((i: any) => i.id.toString() === id);
        }
        return item;
    }

    public fetchData = (data: any | any[] | null, docData: any) => {
        if(!data) return;
        else if (Array.isArray(data)) {
            data.forEach((d) => { 
                this.defineRecord(d, docData) 
            });
        }
        else {
            this.defineRecord(data, docData);
        }
    }

    public saveDocument = async (data: any | any[] | null, docData: any, document: any, docRef: any) => {
        this.fetchData(data, docData);
        this.localStorageHandler.storeData(docData);
        return document.exists() ?
            await updateDoc(docRef, docData) :
            await setDoc(docRef, docData);
    }

    public cleanUndefinedFields = (data: any) => {
        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });
    }

    public defineRecord = (data: any, doc: any) => {
        const index = doc[this.endpoint].data.findIndex((i: any) => i.id === data.id);
        this.cleanUndefinedFields(data);
        
        if (index >= 0) {
            doc[this.endpoint].data[index] = data;
            return;
        }

        if (!data.id) data.id = doc[this.endpoint].lastId + 1;
        doc[this.endpoint].lastId = data.id;
        doc[this.endpoint].updatedDate = new Date();
        doc[this.endpoint].data.push(data);
    }


    public create = async (data: any | any[]) => {
        const [docData, document, docRef] = await this.getUserData();
        return await this.saveDocument(data, docData, document, docRef);
    }


    public update = async (data: any) => {
        const [docData, document, docRef] = await this.getUserData();
        return await this.saveDocument(data, docData, document, docRef);
    }

    public delete = async (id: string, requester: string) => {
        const [data, document, docRef] = await this.getUserData();
        const index = data[this.endpoint].data.findIndex((i: any) => i.id.toString() === id);
        if (index >= 0) {
            data[this.endpoint].data.splice(index, 1);
            return await this.saveDocument(null, data, document, docRef);
        }
        throw new Error("Record not found");
    }
}

export default RequestHandler;
import { Form } from "react-bootstrap"
import { DynamicInputProps } from "./DynamicInput"
import { FieldTypes } from "infrastructure/ModelStructure";

const DynamicCheckboxInput = (props: DynamicInputProps) => {
    return <Form.Check
        type={FieldTypes.Checkbox}
        name={props.field.id}
        checked={props.value}
        onChange={(e) => (props.onChange) ? props.onChange(props.field.id, e.target.checked) : null}
        required={props.field.required}
        disabled={props.disabled}
        label={"Sim"} 
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            if(props.enterKey) props.enterKey();
          }
        }}
    />
}

export default DynamicCheckboxInput